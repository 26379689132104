/*jshint esversion: 6 */

export default (function(){

    const ariaElements = document.querySelectorAll(`[aria-hidden="true"]`);

    for (let i = 0; i < ariaElements.length; i++) {
        if (isVisible(ariaElements[i])) {
            ariaElements[i].ariaHidden = false;
        }
    }

    // others do this check like jquery https://github.com/jquery/jquery/blob/main/src/css/hiddenVisibleSelectors.js
    function isVisible(el) {
        return !!( el.offsetWidth || el.offsetHeight || el.getClientRects().length );
    }

});