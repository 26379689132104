/*jshint esversion: 6 */

export default (function(){

    const desktopViewportContent = 'width=1200, initial-scale=1.0';
    const responsiveViewportContent = 'width=device-width, initial-scale=1';
    const hideClass = 'd-lg-none';

    let links = document.getElementsByClassName('js-toggle-desktop-site');

    let setLinksState = function (responsive) {
        for (let i = 0; i < links.length; i++) {
            let link = links[i];
            if (responsive) {
                link.classList.remove('desktop');
                link.classList.add('responsive');
            } else {
                link.classList.remove('responsive');
                link.classList.add('desktop');
            }
        }};

    let setDesktopView = function (viewport) {

        setLinksState(false);
        viewport.setAttribute('content', desktopViewportContent);
        localStorage.setItem('responsive', 'false');
        toggleLinkCopy();
        setLinkVisibleAlways(true);
        console.log('Desktop view set');
    }

    let setResponsiveView = function (viewport) {

        // Keep this above the potential early return.
        setLinksState(true);

        if (viewport.getAttribute('content') == responsiveViewportContent) {
            return;
        }
        viewport.setAttribute('content', responsiveViewportContent);
        localStorage.setItem('responsive', 'true');
        toggleLinkCopy();
        setLinkVisibleAlways(false);
        console.log('Responsive view set');
    }

    let setLinkVisibleAlways = function (showAlways) {
        if (showAlways) {
            for (let i = 0; i < links.length; i++) {
                links[i].parentElement.classList.remove(hideClass);
            }
        } else {
            for (let i = 0; i < links.length; i++) {
                links[i].parentElement.classList.add(hideClass);
            }
        }
    }

    let toggleLinkCopy = function () {
        let currentCopy = links[0].textContent;
        console.log("old resolution text: " + currentCopy);
        let newCopy = '';
        switch(currentCopy) {
            case 'Desktop Site':
                newCopy = "Mobile Site";
                break;
            case 'Mobile Site':
                newCopy = "Desktop Site";
                break;
            case 'Sitio de Escritorio':
                newCopy = "Sitio Movil";
                break;
            case 'Sitio Movil':
                newCopy = "Sitio de Escritorio";
                break;
        }
        console.log("new resolution text: " + newCopy);

        for (let i = 0; i < links.length; i++) {
            links[i].textContent = newCopy;
        }
    }

    let viewport = document.querySelector("meta[name=viewport]");

    // read localhost and set viewport based on it. --------------------------
    if (localStorage.getItem('responsive') === 'false') {
        setDesktopView(viewport);
    } else {
        setResponsiveView(viewport);
    }


    for (let i = 0; i < links.length; i++) {
        links[i].addEventListener('click', function(event) {
            event.preventDefault();

            console.log('toggled desktop/responsive');

            if (viewport.getAttribute('content') === responsiveViewportContent) {
                setDesktopView(viewport);
            } else {
                setResponsiveView(viewport);
            }
        });
    }



});