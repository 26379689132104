/*jshint esversion: 6 */

export default (function () {
    const backToTopLink = document.querySelector(".back-to-top-link");

    new IntersectionObserver(topObserver => {
        topObserver[0].boundingClientRect.y < 0 ? (
                backToTopLink.dataset.visible = true,
                    console.log('true vis' + topObserver[0].boundingClientRect.y)) : (
                        backToTopLink.dataset.visible = false,
                            console.log('false vis' + topObserver[0].boundingClientRect.y
                            )
        );
    }).observe(document.querySelector("#sitewide-snippets"));
});