/*jshint esversion: 6 */

export default (function(){

    async function postAnalyticsJSON(data) {
        try {
            const response = await fetch('https://j4ucw22pa5cukmxryueeoml74m0jeuey.lambda-url.us-east-1.on.aws/', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            });
            const responseBody = await response.json();
            if (data.browser_user_id === '') {
                localStorage.setItem('analytics_id', responseBody.browser_user_id)
            }

            // debug output
            if(responseBody.browser_url !== undefined) {
                console.log(responseBody);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    }

    let analyticsID = localStorage.getItem('analytics_id');
    let ecancerEmailContents = document.querySelector('#menu-user div').firstElementChild.innerHTML;
    ecancerEmailContents = ecancerEmailContents.substring(0, ecancerEmailContents.indexOf(' '));
    const ecancerEmail = ecancerEmailContents;
    const gaCookie = document.cookie.split('; ').find(row => row.startsWith('_ga='))?.split('=')[1];

    const urlParams = new URLSearchParams(window.location.search);
    const utmTarget = urlParams.get('utm_target');
    const utmSource = urlParams.get('utm_source');

    let data = {
        browser_user_id: analyticsID ?? '',
        browser_url: window.location.href,
        ecancer_email: ecancerEmail ?? '',
        ga_id: gaCookie ?? '',
        utm_target: utmTarget ?? '',
        utm_source: utmSource ?? ''
    };

    postAnalyticsJSON(data);
});