import * as bootstrap from "bootstrap"
import accessibility from "./common/accessibility";
import toggleDesktopSite from "./common/toggleDesktopSite";
import collapseShow from "./common/collapseShow";
import backToTop from "./pages/backToTop";
import userAnalytics from "./common/userAnalytics";
document.addEventListener("DOMContentLoaded", function() {
    toggleDesktopSite();
    collapseShow();
    backToTop();
    accessibility();
    userAnalytics();
});
import elsa_styles from '../scss/elsa-base.scss';