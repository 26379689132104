/*jshint esversion: 6 */

export default (function(){
    const showCollapseAttributeName = 'data-bs-show';
    const inputShowCollapseElements = document.querySelectorAll("["+showCollapseAttributeName+"='collapse']");

    // This lets you show a bootstrap collapse without it toggling on the next click
    // bootstrap collapse appears to only toggle
    // This code uses data-bs-show attribute instead of data-bs-toggle on the control element
    // lets you set a control to only show/open the collapsed element
    inputShowCollapseElements.forEach((showCollapseElement) => {
        showCollapseElement.addEventListener('focus', (event) => {
            if(event.target.hasAttribute('data-bs-target')) {
                $(event.target.dataset.bsTarget).collapse('show');
                console.log(event.target.dataset.bsTarget + ' query elements collapse show');
            }
        });
    });
});